import ProfileImgOne from '../images/about-1.jpg'
import ProfileImgTwo from '../images/about-2.jpg'

const aboutData = {
  id:1,
  title:"Over mij",
  blocks: [
    {
      id:1,
      partOne: "Er stonden thee dampende theebekers op een rond tafeltje op de Vakantiebeurs. Ik ontdekte dat ik " +
                "helemaal opging in de rollercoaster van emoties die met je verhaal gepaard gingen. " +
                "Met vallen en opstaan kon je uiteindelijk een hotel kopen. Ik bewonderde je onwijze doorzettingsvermogen, alleen " +
                "iemand die haar droom zo graag wilde realiseren kon deze constante stroom aan tegenslagen " +
                "doorstaan. Het verhaal werd nog gekker, want op het moment dat je te horen kreeg dat de koop " +
                "rond was, had je ook net een nieuwe baan aangenomen. Er moest tenslotte wel geld in het laadje " +
                "komen. Maar falen was geen optie, het eerste zomerseizoen draaide je succesvol naast een fulltime " +
                "baan. Ik voelde niets anders dan diep respect. Die diepe bewondering zorgde ervoor dat ik zo " +
                "verdrietig werd van wat je me daarna vertelde. Het was die dag 11 januari en er was voor " +
                "aankomend zomerseizoen nog maar een handjevol boekingen binnen. Hoe was het mogelijk dat " +
                "iemand die zoveel had doorstaan om haar droomhotel te verwezenlijken en zo’n tof concept had " +
                "neergezet, onvoldoende boekingen kreeg en niet genoeg omzet? Ik kon niet anders dan haar " +
                "proberen te helpen. Ik had net ontslag genomen bij Europa’s grootste tour operator TUI waar ik " +
                "hotels inkocht over de hele wereld. Er moest toch iets zijn dat ik had geleerd en gezien in de " +
                "afgelopen 15 jaar dat haar kon helpen? ",
      partTwo: "En daar is het idee voor mijn bedrijf geboren. Tijdens dat gesprek klikte het bij mij. Zij is een " +
                "geweldige hotelier wat betreft het neerzetten van een beleving voor haar gasten en hen in de watten " +
                "leggen, maar het commerciële gedeelte van haar business is niet haar sterkste punt. En laat ik daar " +
                "nu eens onwijs goed in zijn! Voor mij is het is afgelopen met hotelier zijn voor de hobby, er een baan " +
                "naast hebben, lage bezettingen en te weinig omzet. Ik zit niet langer tegenover hoteliers als inkoper, " +
                "maar zet al mijn kennis en kunde in om hoteliers te helpen om van hun droom een commercieel " +
                "succes te maken.",
      img:ProfileImgOne
    },
    {
      id:2,
      partOne: "Ik doe niet aan opgedofte hotello-style en fluff. Borrels op vakbeurzen sla ik liever over, want wat " +
                "een gebla. Werd ik vroeger bekritiseerd op mijn directheid, waarderen mijn vriendinnen mij juist om " +
                "mijn eerlijkheid. Ook als het ff niet zo leuk is en vooral als er in mijn ogen onrecht aan de hand is. " +
                "Dacht ik altijd een nieuwsgierig aagje te zijn, blijkt dat ik anderen een goed gevoel geef doordat ze " +
                "voelen dat ik oprecht in hen geïnteresseerd ben. Ik kan niet omgaan met mensen die vinden dat alle " +
                "dingen die hen overkomen door iets of iemand anders komen, maar nooit even in de spiegel kijken " +
                "en denk “goh, zou ik er ook zelf iets aan kunnen doen?” Oh ja, eigenlijk heb ik een hekel aan de " +
                "woorden nooit en altijd. Familie, vrienden, oud collega’s en partners ervaren mij als een box vol " +
                "positiviteit waardoor ze na een gesprek met mij altijd zin hebben om aan de slag te gaan.",
      partTwo: "Ik vind dat je als hotelier je business serieus moet nemen en er goed van moet kunnen leven. Reizen " +
                "gaat niet meer over zoveel en zo goedkoop mogelijk. Het is de kunst om gastvrijheid te bieden " +
                "waardoor de gast die jouw hotel verlaat zich beter voelt over zichzelf. Ik geloof dat een gast dit wil " +
                "ervaren en daarom kiest voor JOU. Zo bereik je een goede bezettingsgraad zonder je in allerlei " +
                "bochten te wringen. Wordt de prijs betaald die het verblijf waard is en niet omdat het de " +
                "goedkoopste deal is. En is er geen margeverlies door het onnodig betalen van commissies aan " +
                "externe partijen omdat de verkoop via eigen gekozen kanalen gerealiseerd wordt.",
      img:ProfileImgTwo
    }
  ],
  subtextTitle: "Als je me zou kennen zou je weten dat",
  subText: "Ik ben gek op CrossFit. Je vind mij op vier dagen van de week om 07:00 ’s ochtends bij CrossFit " +
          "Twente. Ik wil graag gezond ouder worden en ik geloof dat een soepel en sterk lijf me hier helpt. " +
          "Daarnaast is het ook nog eens super gezellig om de dag te beginnen met een groep mensen die ook " +
          "zo gek zijn om om 07:00 uur ’s ochtends al ff lekker tot het gaatje willen gaan. " +
          "Mijn gezicht laat altijd al eerder zien wat ik ergens van vind dan dat mijn mond de woorden heeft " +
          "gesproken. Ik al meer dan de helft van mijn leven met Maik ben dan zonder hem? Hij is een onwijs stoere " +
          "kapitein van een offshore schip en we weten elkaar altijd naar een volgend level te tillen. We " +
          "supporten elkaar in het realiseren van onze dromen. " +
          "Ik ben er dol op als iemand mij iets up- of cross-sells. Ik hou ervan dat er moeite wordt gedaan om " +
          "me extra te dienen als ik er zelf niet aan denk."
}

export default aboutData